.auth0-lock-name {
  color: #808080;
  font-size: 12px !important;
  line-height: 15px !important;
  height: 40px;
}

.auth0-lock-header-logo {
  margin-top: 10px !important;
}

.auth0-lock-header {
  padding: 14px 63px 0px 63px !important;
}
