.BasicInfoView {
  width: 1181px;
  height: 1070px;
  background: #f9fafb;
}

.textInput {
  width: 320px;
}

.Title {
  width: 1181px;
  height: 77px;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}

.TitleText {
  width: 131px;
  height: 28px;
  margin-top: 24px;
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #374151;
}

.Setting {
  height: 994px;
  width: 1181px;
  margin-top: 0;
}

.SettingTitle {
  display: inline-block;
  width: 1027px;
  height: 84px;
  border-bottom: 1px solid #e5e7eb;
  margin-top: 0;
  margin-left: 40px;
}

.SettingTitleText {
  width: 1027px;
  height: 24px;
  margin-top: 24px;
  margin-bottom: 20px;
  margin-left: 0;

  color: #111827;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.teamName {
  display: flex;
  width: 1027px;
  margin-left: 40px;
  border-bottom: 1px solid #e5e7eb;
}

.teamNameText {
  width: 240px;
  height: 20px;

  margin-top: 29px;
  margin-bottom: 29px;
  margin-right: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.teamImage {
  display: flex;
  align-items: center;
  width: 1027px;
  height: 96px;
  margin-left: 40px;
  border-bottom: 1px solid #e5e7eb;
}

.teamImageText {
  width: 240px;
  height: 20px;

  margin-right: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.teamImageData {
  border: 1px solid #e5e7eb;
  border-radius: 7px;
  margin-right: 20px;
  background: #ffffff;
}

.teamImageData p {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 700;
  font-size: 18.1774px;
  line-height: 26px;
  color: #262626;
  margin-top: 15.75px;
}

.teamImageData p {
  margin-top: 15.75px;
  text-align: center;
}

.teamImageButton {
  width: 54px;
  height: 34px;
  font-family: 'Inter';
  letter-spacing: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #374151;
  padding: 0px 0px !important;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.teamImageButton span {
  margin-left: 0;
  margin-right: 0;
  width: 28px;
  height: 16px;
}

.companyName {
  display: flex;
  width: 1027px;
  margin-left: 40px;
  border-bottom: 1px solid #e5e7eb;
}

.companyNameText {
  width: 240px;
  height: 20px;

  margin-top: 29px;
  margin-bottom: 29px;
  margin-right: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.companyWebSite {
  display: flex;
  width: 1027px;
  margin-left: 40px;
  border-bottom: 1px solid #e5e7eb;
}

.companyWebSiteText {
  width: 240px;
  height: 20px;

  margin-top: 29px;
  margin-bottom: 29px;
  margin-right: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.companyTel {
  display: flex;
  width: 1027px;
  height: 90px;
  margin-left: 40px;
  border-bottom: 1px solid #e5e7eb;
}

.companyTelText {
  width: 240px;

  margin-top: 29px;
  margin-bottom: 41px;
  margin-right: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.changeButton span {
  margin: 0;
  width: 70px;
  height: 20px;
}

.inputError {
  color: #f87171;
}
