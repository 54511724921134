@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.auth0-lock-header {
  height: 112px !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: auto !important;
}

.auth0-lock.auth0-lock .auth0-lock-terms {
  font-size: 11px !important;
  line-height: 23px !important;
}

.auth0-lock-sign-up-terms-agreement label input {
  top: 2.5px;
}

.auth0-lock.auth0-lock .auth0-lock-header-bg {
  background-color: #fafafa !important;
}

.auth0-lock-alternative-link {
  color: #8e8e8e !important;
}

@media screen and (max-width: 480px) {
  .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-terms {
    position: static !important;
  }
}

.auth0-lock-terms {
  margin: 0 0 20px;
}
