/* カレンダーアイコンを独自のものにするためのCSS */
input[type='datetime-local'] {
  width: 2000px;
  height: 40px;
  position: relative;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background: transparent;
  z-index: 1;
}

input[type='datetime-local']::after {
  content: '';
  background-image: url(../../assets/samples/bx-calendar.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
